.ant-checkbox-wrapper {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum", "tnum";
    display: contents;
    cursor: pointer;
}

.btn-block {
    width: 100%;
}

.form-control-checkbox {
    width:20px;
    height: 20px;
    display: block;
    cursor: pointer;
}

.et_pb_contact_form_label_schulung {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.et_pb_contact_form_feedback_videos {
    margin: 0px auto;
}

.et_pb_contact_form_feedback_videos .input {
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    background-color: #fff;
    width: 50%;
    margin: 0px auto;
    display: flex;
    border-radius: 0;
    color: #004678;
    font-size: 14px;
    padding: 5px 10px 5px 5px;
}

.et_pb_error_schulung {
    display: flex;
    justify-content: center;
}

.et_pb_section .et_pb_button_0 {
    color: #f2bc27 !important;
    border-width: 2px !important;
    border-color: #f2bc27;
    border-radius: 0;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    background-color: rgba(0, 0, 0, 0);
}


.et_pb_button_0_wrapper_back {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}


.ant-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum", "tnum";
    position: relative;
    top: -.09em;
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;
    outline: none;
    cursor: pointer;
}

.ant-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    border-collapse: separate;
    transition: all .3s;
}


.et_mobile_menu .first-level > a {
    background-color: transparent;
    position: relative;
}

ol, ul {
    list-style-type: none;
}

.second-level {
    display: none;
}

.reveal-items {
    display: block;
}


.et_pb_row_0_tb_header, body #page-container .et-db #et-boc .et-l .et_pb_row_0_tb_header.et_pb_row, body.et_pb_pagebuilder_layout.single #page-container #et-boc .et-l .et_pb_row_0_tb_header.et_pb_row, body.et_pb_pagebuilder_layout.single.et_full_width_page #page-container #et-boc .et-l .et_pb_row_0_tb_header.et_pb_row {
    width: 100% !important;
}


.et_pb_section_0_tb_header.et_pb_section {
    padding: 20px;
    z-index: 10;
}

.et_pb_section_0_tb_header {
    z-index: 10;
    box-shadow: 0 -10px 5px 10px rgba(0, 37, 64, 0.25);
    position: fixed !important;
    top: 0;
    bottom: auto;
    left: 0;
    right: auto;
}

.et_pb_row_0_tb_header.et_pb_row {
    padding-bottom: 0 !important;
    padding-top: 0;
    z-index: 3;

}

.et_pb_text_1_tb_footer.et_pb_text a:hover, .et_pb_text_0_tb_footer.et_pb_text a:hover, .text_under_smiley a:hover {
    color: #ffffff
}

.et_pb_image_0_tb_header.et_pb_module {
    margin-left: 0 !important;
    margin-right: auto !important;
}

.et_pb_module {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
}

.et_pb_image_0_tb_header {
    padding-bottom: 0;
    padding-left: 0;
    margin-top: 5px !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    text-align: left;
}

.et_pb_menu_0_tb_header.et_pb_menu {
    justify-content: flex-end !important;
}

.et_pb_menu_0_tb_header {
    padding: 10px;
    margin: 0 !important;
}

.flyer-title {
    font-size: 5em;
}

.et_pb_section--absolute, .et_pb_section--fixed {
    width: 100%;
}

.et_pb_fullwidth_menu--style-left_aligned .et_pb_menu_inner_container, .et_pb_fullwidth_menu--style-left_aligned .et_pb_row, .et_pb_menu--style-left_aligned .et_pb_menu_inner_container, .et_pb_menu--style-left_aligned .et_pb_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.et_pb_menu_inner_container {
    position: relative;
}

.et_pb_fullwidth_menu--style-left_aligned.et_pb_text_align_right .et_pb_menu__menu > nav > ul, .et_pb_fullwidth_menu--style-left_aligned.et_pb_text_align_right .et_pb_menu__wrap, .et_pb_menu--style-left_aligned.et_pb_text_align_right .et_pb_menu__menu > nav > ul, .et_pb_menu--style-left_aligned.et_pb_text_align_right .et_pb_menu__wrap {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

#top-menu, .et-menu, nav#top-menu-nav, nav.et-menu-nav {
    float: left;
}

.et_pb_fullwidth_menu .et-menu-nav > ul, .et_pb_menu .et-menu-nav > ul {
    padding: 0 !important;
    line-height: 1.7em;
}

.edit_container_recruiting {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;
}

.et_pb_button_recruiting {
    margin: 0 auto;
    text-align: center;
    display: block;
}

.flyer-counter {
    margin-top: -6px;
    padding-top: 0;
    text-align: right;
}

.flyer-counter-last {
    clear: both;
}

.flyer-jobart {
    display: block;
    margin-bottom: 10% !important;
}

.flyer-errors {
    padding-top: 5px;
}

.et_pb_contact_field_flyer {
    margin: 0 auto;
}

.mobile_menu_bar {
    position: relative;
    display: block;
    line-height: 0;
}

.et_pb_fullwidth_menu .et-menu, .et_pb_menu .et-menu {
    margin-left: -11px;
    margin-right: -11px;
}

#left-area ul, .comment-content ul, .entry-content ul, .et-l--body ul, .et-l--footer ul, .et-l--header ul, body.et-pb-preview #main-content .container ul {
    padding: 0;
    line-height: 26px;
}

.et_pb_fullwidth_menu--without-logo .et_pb_menu__menu > nav > ul > li, .et_pb_menu--without-logo .et_pb_menu__menu > nav > ul > li {
    margin-top: 8px;
}

.et_pb_fullwidth_menu .et_pb_menu__menu > nav > ul > li, .et_pb_menu .et_pb_menu__menu > nav > ul > li {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin: 0;
}

.check-format {
    padding: 16px 16px 16px 0;
    display: block;
}

.check-format-error {
    border: 1px solid #000;
}

.flyer-img {
    max-width: 100%;
    height: auto;
}

.flyer_container {
    display: flex;
    gap: 50px;
}

.linkedInImgForm {
    align-self: center;
}

.textarea_ad {
    resize: none;
}

.flyer-aligncenter {
    text-align: center;
}

.flyerForm {
    flex-grow: 1;
    flex-basis: 50%;
    align-self: flex-end;
}

.linkedin-field {
    margin-bottom: 6% !important;
}

.imgForm {
    flex-grow: 1;
    flex-basis: 50%
}

.flyer-headline {
    font-size: 1.5em;
    line-height: 2em;
}


.et_pb_fullwidth_menu .et-menu > li, .et_pb_menu .et-menu > li {
    padding-left: 11px;
    padding-right: 11px;
}

.et-menu li {
    display: inline-block;
    font-size: 14px;
    padding-right: 22px;
}

.et-menu .menu-item-has-children > a:first-child {
    padding-right: 20px;
}

.et_pb_fullwidth_menu--without-logo .et_pb_menu__menu > nav > ul > li > a, .et_pb_menu--without-logo .et_pb_menu__menu > nav > ul > li > a {
    padding-bottom: 8px;
}

.et_pb_menu_0_tb_header.et_pb_menu ul li a {

    font-weight: 600;
    color: #004678;
}

.header-content-container.center {
    -webkit-align-self: auto;
    align-self: auto;
    padding-top: 85px;
    min-height: calc(100vh - 80px);

}

.border-content {
    border: 0 solid #004678;
    border-left-width: 8vw !important;
}


a {
    color: #024373
}

img {
    max-width: 100%;
    height: auto;
}

.et_pb_image img {
    position: relative;
}

.et_pb_image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    line-height: 0;
}

input:disabled {
    cursor: not-allowed;
}

.kontakt_paddingtop {
    display: inline-block;
    color: #194674;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    text-align: center;
    margin: 0 auto;
    width: 100%;
}

.spinner-border-home {
    margin-top: 30px !important;
    margin-left: 20px !important;
    color: #004678 !important;
    vertical-align: middle;
}

.spinner-border-search {
    margin-top: 12px !important;
    margin-left: 20px !important;
    color: #004678 !important;
    vertical-align: middle;
}

select {
    overflow-x: hidden;
}

.selectedBewerberList {
    text-align: left;
}

.spinner-border {
    margin-left: 20px !important;
    color: #004678 !important;
    vertical-align: middle;
}

.text_loading {
    display: block;
    margin: 0 auto;
    width: 32px;
    padding-bottom: 30px;
}

.spinner-loading {
    margin-left: 0 !important;
}

.whatsapp_button {
    width: 100px;
    padding-top: 20px;
    margin: 0 auto;
    display: block;
}

.dropdown-kontaktformular {
    width: 100%;
    background: rgba(255, 255, 255, 0.0);
    padding: 16px 16px 16px 16px;
    margin-top: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #004678;
    font-size: 16px;
    font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
    color: #004678;
}

.dropdown-kontaktformular:focus {
    border-color: #004678 !important;
    color: #004678;
}

.navbar {
    min-height: 90px !important;
    width: 100% !important;
    z-index: 999 !important;
    background-color: #fff !important;
    box-shadow: 0 -10px 5px 10px rgba(0, 37, 64, 0.25);

}

.et_pb_profile_submit {
    margin-top: 0 !important;
}

.et_pb_backend_contact {
    margin-top: 40px !important;
}

.nav-item {
    font-weight: 600;
    color: #004678 !important;
    font-size: 14px;
}

.err_import {
    text-align: center;
    padding-top: 20px;
    display: block;
    margin: 0 auto;
}

.fileUpload {
    text-align: center;
    margin: 0 auto;
    padding-bottom: 20px;
    display: block;
    width: 500px;
}


.text-center {
    text-align: center;
    display: block;
    margin: 0 auto;
}

.list {
    text-align: left;
    max-width: 750px;
    margin: auto;
}

.show {
    display: block;
}

.button-mobile {
    display: none
}

.button-desktop {
    display: inherit;
}

.et_pb_confirmEmail {
    margin-top: 10px !important;
    float: left;
    width: 100%;
}


.et_confirmEmail_text {
    line-height: 2em;
    color: #004678;
}

.et_confirmNewEmail_text {
    margin-top: 20px;
    display: inline-block;
}

.et_pb_button_1_wrapper .et_pb_button_1, .et_pb_button_1_wrapper .et_pb_button_1:hover {
    padding: 10px 25px !important;
}

.et_pb_button_1 {
    color: #f2bc27 !important;
    border-width: 2px !important;
    border-color: #f2bc27;
    border-radius: 0;
    letter-spacing: 1px;
    font-size: 10px;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    background-color: rgba(0, 0, 0, 0);
}

.et_pb_button_1 {
    transition: color 300ms ease 0ms, background-color 300ms ease 0ms;
}


.et_pb_button_1, .et_pb_button_1:after {
    transition: all 300ms ease 0ms;
}

.et_pb_button_1 {
    font-size: 13px;
    font-weight: 500;
    padding: .3em 1em;
    line-height: 1.7em !important;
    background-color: transparent;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    border: 2px solid;
    border-radius: 3px;
    transition: all .2s;
}

.et_pb_button_1:hover {
    color: #ffffff !important;
    background-image: initial;
    background-color: #f2bc27;
}

.et_pb_button_1:hover {
    border: 2px solid transparent;
}


.hide {
    display: none;
}

.et_pb_button_zusatz {
    padding-top: 10px;
    width: 100%;
    justify-content: center;
    display: flex;

}

.et_pb_button_zusatz_span {
    width: 100%;
    display: block;
}


h4, h5 {
    color: #004678;
}

a, a:hover {
    text-decoration: none;
}

#et_pb_contact_name_0::placeholder, .et_pb_contact_field_0.et_pb_contact_field .input, .et_pb_contact_field_0.et_pb_contact_field .input[type="checkbox"] + label, .et_pb_contact_field_0.et_pb_contact_field .input[type="radio"] + label, .et_pb_contact_field_0.et_pb_contact_field .input[type="checkbox"]:checked + label i:before, .et_pb_contact_field_0.et_pb_contact_field .input::placeholder {
    color: #004678;
}

.submit-form {
    max-width: 300px;
    margin: auto;
}

.text_under_smiley a, .text_under_smiley a:hover {
    transition: all .4s ease-in-out;
}

.edit-form {
    max-width: 300px;
    margin: auto;
}

.job {
    padding-top: 30px;
    padding-bottom: 20px;
}

.page-link {
    color: #004678;
}

.page-item.active .page-link {
    background-color: #004678;
    border-color: #004678;
}


.errorMessage {
    border-color: #ff0000 !important;

}

.loadingMessage {
    display: block;
    padding-top: 30px;
    margin: auto;
    font-weight: 600;
}

.search {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    padding-bottom: 30px;
}

.search_button {
    margin-left: 20px;
}

.et_pb_section_parallax {
    position: relative;
    overflow: hidden;
}

.et_pb_section_6.et_pb_section {
    padding-top: 100px;
    padding-bottom: 230px;
}

.et_pb_kontaktformular {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.navbar-light .navbar-toggler {
    border-color: transparent !important;
}

.navbar-light .navbar-toggler:focus {
    outline: none;
}


.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(25, 70, 116, 1)' stroke-width='2.5' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    width: 2em;
    height: 1.8em;
    margin-right: -11px;
}

.navbar-light .navbar-toggler-icon:hover {
    border-color: transparent;
}


.et_parallax_bg_wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.et_pb_row_12.et_pb_row {
    padding: 0 !important;
}


.et_pb_row_12 {
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 -15px 60px 0 rgba(29, 61, 71, 0.2);
}


.et_pb_contact_form_0.et_pb_contact_form_container {
    padding: 0 60px 50px;
    margin-bottom: 0 !important;
}


.et_pb_contact_form .et_pb_contact_field_half {
    width: 50%;
    float: left;
}


.et_pb_datepicker.et_pb_contact_field {
    margin-top: 0 !important;
    background-position: 50%;
    background-size: cover;
    float: left;
    width: 100%;
}

.et_pb_error_element:first-child {

    margin-top: 20px !important;
    margin-bottom: 20px !important;
    background-position: 50%;
    background-size: cover;
    float: left;
    width: 100%;

}

.et_pb_error_element:not(:first-child) {

    margin-bottom: 20px !important;
    background-position: 50%;
    background-size: cover;
    float: left;
    width: 100%;

}

.datenschutz_container.et_pb_contact_field {
    padding-top: 10px !important;
    background-position: 50%;
    background-size: cover;
    float: left;
    width: 100%;
}

.et_contact_bottom_container_backend {
    margin-top: 5px !important;
}

.datenschutz_container_home.et_pb_contact_field {
    padding-top: 30px !important;
    background-position: 50%;
    background-size: cover;
    float: left;
    width: 100%;
}

.wann_arbeiten {
    display: flex;
    color: #004678;
    padding-bottom: 10px;
    justify-content: space-between;
    width: 100%;

}

.wann_arbeiten_kurzlang {
    display: flex;
    justify-content: left;
    color: #004678;
    padding-top: 10px;
    float: left;
    width: 100%;

}

.zusatzinfos-notice {

    padding-bottom: 10px !important;
}

.ant-btn:focus, .ant-btn:hover {
    border-color: #f2bc27 !important;
    background-color: #f2bc27 !important;
}

.abstand_verify {
    padding-top: 40px !important;
}

.zusatzinfos_container.et_pb_contact_field {
    background-position: 50%;
    background-size: cover;
    float: left;
    width: 100%;
}

p.et_pb_contact_field {
    margin-bottom: 3%;
    background-position: 50%;
    background-size: cover;
}

p.et_pb_kontaktformular_select {
    padding: 0 0 0 0;
}


.et_pb_contact_form p.et_pb_contact_field {
    padding: 0 0 0 3%;
}

.et_pb_contact_form p.et_pb_contact_field_first, .et_pb_contact_form div.et_pb_contact_field_first {
    padding: 0 !important;
}


.et_pb_contact_form_container .et_pb_contact_field_0.et_pb_contact_field {
    margin-top: 20px;
}


.et_pb_contact_field_search {
    width: 100% !important;
}


.et_pb_section .et_pb_jobsearch.et_pb_jobsearch_container.et_pb_module .et_pb_button, .ant-btn,
.et_pb_section .et_pb_contact_form_0.et_pb_contact_form_container.et_pb_module .et_pb_button {
    color: #ffffff !important;
    border-width: 12px !important;
    border-color: #f2bc27;
    border-radius: 0;
    height: 55px;
    letter-spacing: 1px;
    font-size: 14px;
    font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    background-color: #f2bc27;
    margin-top: 20px;
}

.react-hls-player {
    margin: 0 auto;
    display: block;
}

.et_pb_button_search {
    margin-top: 0 !important;
    padding: .3em 2em


}


.et_pb_button_search:hover, .et_pb_module .et_pb_button_search:hover {

    padding-right: 3em;
}

.et_pb_section .et_pb_contact_form_0.et_pb_contact_form_container.et_pb_module .et_pb_button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.error_container_element_feedback {
    font-family: 'Roboto Condensed', Helvetica, Arial, Lucida, sans-serif !important;
    color: rgb(217, 23, 23) !important;
    font-weight: bold;
    font-size: 14pt;
}

.video_container {
    display: flex;
    justify-content: center;
    gap: 100px;
    padding-bottom: 100px;
}

.feedback_error {
    color: #004678;
    font-family: 'Roboto Condensed', Helvetica, Arial, Lucida, sans-serif !important;
    font-size: 14pt;
}

.et_pb_section .et_pb_contact_form_feedback .et_pb_button, .et_pb_section .et_pb_contact_form_feedback_bonusgutschein .et_pb_button,
.et_pb_section .et_pb_contact_form_feedback_videos .et_pb_button {

    color: #ffffff !important;
    border-width: 12px !important;
    border-color: #f2bc27;
    border-radius: 0;
    height: 55px;
    letter-spacing: 1px;
    font-size: 14px;
    font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    background-color: #f2bc27;
    margin-top: 20px
}

.et_pb_module .et_pb_button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.placeholder-margin-50 {
    margin-bottom: 50px;
}

.et_pb_button:hover, .et_pb_module .et_pb_button:hover {
    border: 2px solid transparent;
    padding: .3em 2em .3em .7em;
}

.table-verify-text {
    text-align: left;
}

.et_pb_section .et_pb_contact_form_feedback .et_pb_button:hover:after, .et_pb_section .et_pb_contact_form_feedback_bonusgutschein .et_pb_button:hover:after, .et_pb_section .et_pb_contact_form_feedback_videos .et_pb_button:hover:after {
    margin-left: .3em;
    left: auto;
    opacity: 1;
}

.et_pb_section .et_pb_contact_form_feedback .et_pb_button:after, .et_pb_section .et_pb_contact_form_feedback_bonusgutschein .et_pb_button:after, .et_pb_section .et_pb_contact_form_feedback_videos .et_pb_button:after {
    font-size: inherit !important;
    line-height: inherit;
}

.et_pb_section .et_pb_contact_form_0.et_pb_contact_form_container.et_pb_module .et_pb_button:hover:after {
    margin-left: .3em;
    left: auto;
    opacity: 1;
}

.et_pb_section .et_pb_jobsearch.et_pb_jobsearch_container.et_pb_module .et_pb_button:after {
    line-height: inherit;
    font-size: inherit !important;
    margin-left: -1em;
    left: auto;
}

.et_pb_section .et_pb_contact_form_0.et_pb_contact_form_container.et_pb_module .et_pb_button:after {
    line-height: inherit;
    font-size: inherit !important;
    margin-left: -1em;
    left: auto;
}

.et_pb_button:hover:after, .et_pb_more_button:hover:after, .et_pb_newsletter_button:hover:after, .et_pb_pricing_table_button:hover:after, .et_pb_promo_button:hover:after {
    opacity: 1;
    margin-left: 0;
}

.et_pb_button_0:after {
    font-size: 32px;
    line-height: 0;
    content: "" !important;
    opacity: 0;
    position: inherit;
    margin-left: 0;
    -webkit-transition: all .2s;
    transition: all .2s;
    text-transform: none;
    -webkit-font-feature-settings: "kern" off;
    font-feature-settings: "kern" off;
    font-variant: none;
    font-style: normal;
    font-weight: 400;
    text-shadow: none;
}

.et_pb_button_0:hover, .et_pb_module .et_pb_button_0:hover {
    padding: .3em 1em;
}

.et_pb_button:after, .et_pb_button:before {
    font-size: 32px;
    line-height: 1em;
    content: "\35";
    opacity: 0;
    position: absolute;
    margin-left: -1em;
    -webkit-transition: all .2s;
    transition: all .2s;
    text-transform: none;
    -webkit-font-feature-settings: "kern" off;
    font-feature-settings: "kern" off;
    font-variant: none;
    font-style: normal;
    font-weight: 400;
    text-shadow: none;
}

.et_contact_bottom_container {
    float: right;
    text-align: right;
    display: -moz-flex;
    display: -ms-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.et_contact_bottom_container_confirmMail {
    float: left;
    text-align: left;
    margin: 0 0 0 3%;
    display: -moz-flex;
    display: -ms-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.edit_container_profile {
    float: right;
}

.edit_container_profile button:first-child {
    margin-right: 20px;
}

.et_contact_bottom_container_profile {

    text-align: center;
}


.error_container_element {
    color: rgb(217, 23, 23) !important;
}


.et_pb_row_table_backend {
    max-width: 100% !important;
}

.et_pb_contact_delete, .ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:hover {
    background-color: rgb(217, 23, 23) !important;
    border-color: rgb(217, 23, 23) !important;
}

.profile_errors {
    padding-top: 20px;
}

.et_contact_bottom_container_search {
    display: flex;
    justify-content: center;
}

.et_pb_custom_button_icon.et_pb_button:after, .et_pb_login .et_pb_custom_button_icon.et_pb_button:after {
    content: attr(data-icon);
}

.et_pb_jobsearch.et_pb_jobsearch_container {
    padding-top: 0;
    margin-bottom: 0 !important;
}

.et_pb_jobsearch .input, .et_pb_contact_form_0 .input, .et_pb_contact_form_0 .input[type="checkbox"] + label i, .et_pb_contact_form_0 .input[type="radio"] + label i {
    background-color: rgba(0, 0, 0, 0) !important;
}

.et_pb_jobsearch .input, .et_pb_contact_form_0.et_pb_contact_form_container .input, .et_pb_contact_form_0.et_pb_contact_form_container .input[type="checkbox"] + label i, .et_pb_contact_form_0.et_pb_contact_form_container .input[type="radio"] + label i {
    border-width: 1px;
    border-style: solid;
    border-color: #004678;
}

.et_pb_contact_form div.et_pb_contact_field {
    padding: 0 0 0 3%;
}

.et_pb_contact_field_checkbox {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    border-width: 0;
    border-radius: 0;
    color: #999;
    font-size: 14px;
    padding: 0 0 0 3%;

}

.et_pb_contact_field_checkbox_group {
    margin-top: 20px;
    margin-bottom: 20px;
    display: block;
}

.ant-checkbox-group-item, .ant-radio-wrapper {
    font-size: 12pt;
    color: #004678 !important;
}


.et_pb_contact_field_checkbox_group_left {
    float: left;
    margin-right: 20px;
}

.et_pb_contact_field_checkbox_group_right {
    float: right;
    margin-left: 20px;
}

.et_pb_contact_field_checkbox_group_right span {
    text-align: right;
}

.et_pb_contact_field_checkbox_group_left span {
    text-align: left;
}

.work_label {
    display: block;
    padding-bottom: 20px;
}

.et_pb_contact_field_radio_kurzlang {
    display: flex;
    float: left;
    width: 100%;
    justify-content: center;
}


.form-check-input {
    margin-left: 0;
    margin-top: 0;
    float: left;
    position: inherit;
}

.datenschutz_container {
    margin-bottom: 0.5rem !important;
}

.zusatzinfos_container {
    margin-top: 20px !important;
}


.zusatzinfos_container_backend {
    margin-top: 30px !important;
}

.zusatzinfos_container_edit {
    margin-top: 30px !important;
}

.form-check-label {
    color: #004678;
    font-size: 12pt;
    margin-left: 10px;
    margin-top: -1%;
}

.et_pb_contact_form_search_top {

    display: flex;
    justify-content: center;
}

.et_pb_contact_parallax {

}

.et_parallax_bg {
    background-image: url(../src/images/collage.jpg);
    background-position: top center;
    position: absolute;
    overflow: hidden !important;
    top: 0;
    display: inline-block;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1000vh;
    background-size: contain;
    background-repeat: no-repeat;
}

.et_pb_jobsnotfound {
    text-align: center;
    font-weight: 600;
    color: rgb(161, 15, 15);
    margin-bottom: 2%;
}


.et_pb_contact_form .input, .et_pb_contact_form p textarea, .et_pb_subscribe .et_pb_contact_field .input, .et_pb_subscribe .et_pb_contact_field textarea {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    background-color: #eee;
    width: 100%;
    border-radius: 0;
    color: #004678 !important;
    font-size: 14px;
    padding: 16px;
}

.et_pb_text_14 {
    padding-top: 50px !important;
}

.et_pb_text_14 h2 {
    font-weight: 700;
    font-size: 48px;
    color: #002540 !important;
    line-height: 1.6em;
    text-align: center;
}


.et_pb_button_2 {
    color: #f2bc27 !important;
    border-width: 2px !important;
    border-color: #f2bc27;
    border-radius: 0;
    letter-spacing: 1px;
    font-size: 14px;
    font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    background-color: rgba(0, 0, 0, 0);
}

.et_pb_button_2_wrapper .et_pb_button_2, .et_pb_button_2_wrapper .et_pb_button_2:hover {
    padding: 10px 25px !important;
}

.et_pb_contact_field_0:disabled {
    background-color: #eee !important;
}


.upload {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    padding-bottom: 30px;
}

.register-bewerberpool {
    padding-top: 30px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.add-bewerber-backend {
    padding-top: 30px;
    padding-bottom: 100px;
}

input[type="submit"] {
    padding: 5px;
    background-color: transparent;
    color: black;
    border: 1px solid black;
    width: 80px;
    margin-left: 5px;
    cursor: pointer;
}

.paddingtop-30 {
    padding-top: 30px;
}

input[type="submit"]:hover {
    background-color: #282c34;
    color: antiquewhite;
}


.search > input[type="text"] {
    width: 40%;
    min-width: 170px;
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    padding-bottom: 40px;
}

.et_pb_button_flex_wrap {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 12px;
}

.apply-link {
    font-size: 13pt;
}

.job-city {
    font-size: 13pt;
    font-weight: 600;
    padding-bottom: 10px !important;
    color: #004678;
}

.job-city-color {
    color: #004678;
}

.job-time {

    font-size: 13pt;
    font-weight: 600;
}

.job-times {

}

.job-div {
    padding-top: 10px !important;
}

.empty-az {

}

.et_pb_profilePadding {
    padding-bottom: 200px;
}

.et_pb_birthdate_field {
    padding-top: 30px;
}

.info-notice-phone {

    display: block;
    font-size: 10pt;

    color: #194674;
    padding-top: 8px;
}

.info-notice-profile {

    display: block;
    color: #194674;

    padding: 20px 0 0 0;

}

.info-notice-profile-verify {
    text-align: center;
    padding-top: 10px;
    display: block;
    color: #194674;
}

p.et_pb_contact_field_last {
    float: left;
    width: 100%;
}

.react-datepicker-wrapper {
    display: inherit !important;
}

.et_phone_notice_container {
    padding-bottom: 2em;
}

.navbar {
    min-height: 80px;
}

#basic-navbar-nav {
    margin-top: 20px;
}

.navbar-light .navbar-nav .nav-link {
    color: #194674;

}


.et_pb_menu_0_tb_header.et_pb_menu ul li a:hover, .et_pb_menu_0_tb_header.et_pb_menu ul li a.active {
    color: #eabd4b !important;
}


#collasible-nav-dropdown, .login-nav {
    color: #000;
    margin-left: 60px;
}

.et_pb_section_1 {
    padding-top: 20px !important;
    padding-bottom: 110px;
    background-color: rgba(0, 0, 0, 0) !important;
    border-color: #f2bc27 #f2bc27 #f2bc27 #004678 !important;
    border-left-width: 8vw !important;
}

.et_pb_column_2_tb_footer {
    display: flex;
    justify-content: flex-end;
}

.et_pb_row .et_pb_column.et-last-child, .et_pb_row .et_pb_column:last-child, .et_pb_row_inner .et_pb_column.et-last-child, .et_pb_row_inner .et_pb_column:last-child {
    margin-right: 0 !important;
}

.et_pb_section {
    position: relative;
    background-color: #fff;
    padding: 54px 0;
}

.et_pb_section {
    background-size: 100%;
}

.et_pb_with_border {
    position: relative;
}

.et_pb_column {
    float: left;
    background-size: cover;
    background-position: 50%;
    position: relative;
    min-height: 1px;
}

.et_pb_column_custom {
    background-size: cover;
    background-position: 50%;
    position: relative;
    min-height: 1px;
}

.et_pb_row {
    padding: 15px 0;
    width: 80%;
    margin: auto;
    max-width: 1080px;
    position: relative;
}


.text_over_search {
    text-align: justify;
    padding-bottom: 20px;
    color: #004678;
}

.text_over_search_21 {
    text-align: center !important;
    font-weight: 600;
    padding-top: 20px;
}

.text_under_search {
    text-align: justify;
    padding-top: 40px;
    color: #004678;
}

.absatz_under_search {
    display: block;
    text-align: center;
    margin-top: 10px;
}

.h5_under_search {
    color: #004678;
    padding-bottom: 20px;
}

.et_pb_text_1_folgend {
    font-weight: 400;
}

.et_pb_text_1 {
    font-family: Helvetica, Arial, Lucida, sans-serif;
}

.h5_verified {
    color: #004678;
    padding-bottom: 20px;
    margin: 0 auto;
    text-align: center;
}

.h5_error_search {
    color: rgb(161, 15, 15);
    padding-bottom: 10px;
    padding-top: 20px;
}

.ant-message .anticon {
    top: -2px !important;
}

.jobs {
    padding-top: 20px;
}

.verlinkung_unten {
    color: #f2bc27
}

.et_pb_divider_0.et_pb_module {
    margin-left: auto !important;
    margin-right: auto !important;
}

.et_pb_divider_0 {
    max-width: 70px;
}

.et_pb_divider {

    position: relative;
}

.et_pb_margin_top {
    margin-top: 20px;
}

.et_pb_space {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 23px;
}

.et_pb_divider_position_center:before {
    top: 50% !important;
}

.et_pb_divider:before {
    content: "";
    width: 100%;
    height: 1px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    position: absolute;
    left: 0;
    top: 0;
}

.et_pb_divider_0:before {
    border-top-color: #f2bc27;
    border-top-width: 3px;
}

.et_pb_section .et_pb_button_0 {
    color: #f2bc27 !important;
    border-width: 2px !important;
    border-color: #f2bc27;
    border-radius: 0;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    background-color: rgba(0, 0, 0, 0);
}

.et_pb_button_module_wrapper {
    float: right;
}

.et_pb_button_0_wrapper .et_pb_button_0, .et_pb_button_0_wrapper .et_pb_button_0:hover {
    padding: 10px 25px !important;
}

.et_pb_button, .et_pb_button_inner {
    position: relative;
}

.et_pb_column_2_5 {
    margin-bottom: 50px;
}


.et_pb_contact_delete {
    padding-left: 20px;
}

.et_pb_button {
    font-size: 20px;
    font-weight: 500;
    padding: .3em 1em;
    line-height: 1.7em !important;
    background-color: transparent;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    border: 2px solid;
    border-radius: 3px;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.et_pb_button_0, .et_pb_button_0:after {
    transition: all 300ms ease 0ms;
}

.et_pb_button_0 {
    transition: color 300ms ease 0ms, background-color 300ms ease 0ms;
}


.et_pb_section .et_pb_button_0:hover {
    color: #ffffff !important;
    background-image: initial;
    background-color: #f2bc27;
}

.et_pb_button_module_wrapper > a {
    display: inline-block;
}

.et_pb_divider_internal {
    display: inline-block;
    width: 100%;
}

.et_pb_divider:after, .et_pb_space:after {
    content: "";
    display: table;
}

.et_pb_row:after, .et_pb_row_inner:after, .et_pb_slides:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
    width: 0;
}


.et_pb_with_border {
    border: 0 solid #333
}


.et_pb_text_0_tb_footer.et_pb_text a, .et_pb_text_1_tb_footer.et_pb_text a {
    color: #bfe5ff;
    transition: color 300ms ease 0ms;
}

.et_pb_text_0_tb_footer {
    font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
    font-size: 14px;
    line-height: 1.2em;
    padding: 0 !important;
    float: left;
    margin: 0 20px 0 0 !important;
    display: block;
    height: fit-content;
    align-self: center;
}

.et_pb_text_1_tb_footer {
    font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
    font-size: 14px;
    line-height: 1.2em;
    padding: 0 0 0 !important;
    margin: 0 !important;
    float: left;
    display: block;
    height: fit-content;
    align-self: center;
}


p:not(.has-background):last-of-type {
    padding-bottom: 0
}

.et_pb_text_0_tb_footer p {
    line-height: 1.2em;
}

.et_pb_column_0_tb_footer {
    display: flex;
    align-content: center !important;
}

.et-l--footer {
    display: block;
}

.et_pb_section_0_tb_footer.et_pb_section {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #002540 !important;
}

.et_pb_row_0_tb_footer.et_pb_row {
    z-index: 3;

    padding-top: 0;
    padding-bottom: 0;
}

.et_pb_row_0_tb_footer {
    padding-right: 0;

}


.et_pb_fullwidth_menu .et_pb_menu__wrap, .et_pb_menu .et_pb_menu__wrap {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    opacity: 1;
}


.et_pb_fullwidth_menu .et_pb_menu__menu, .et_pb_fullwidth_menu .et_pb_menu__menu > nav, .et_pb_fullwidth_menu .et_pb_menu__menu > nav > ul, .et_pb_menu .et_pb_menu__menu, .et_pb_menu .et_pb_menu__menu > nav, .et_pb_menu .et_pb_menu__menu > nav > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.et_pb_fullwidth_menu .et-menu-nav, .et_pb_fullwidth_menu .et-menu-nav > ul, .et_pb_menu .et-menu-nav, .et_pb_menu .et-menu-nav > ul {
    float: none;
}

.et-menu, nav.et-menu-nav {
    float: left;
}

.et_pb_fullwidth_menu .et_pb_menu__menu > nav > ul, .et_pb_menu .et_pb_menu__menu > nav > ul {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.et_pb_fullwidth_menu .et_pb_menu__menu, .et_pb_menu .et_pb_menu__menu {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}


.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.et_pb_css_mix_blend_mode_passthrough {
    mix-blend-mode: unset !important;
}


.et_pb_image_0_tb_footer .et_pb_image_wrap {
    display: block;
}

.et_pb_image .et_pb_image_wrap {
    display: inline-block;
    position: relative;
    max-width: 100%;
}

.et_pb_image_1_tb_footer:hover, .et_pb_image_2_tb_footer:hover, .et_pb_image_3_tb_footer:hover, .et_pb_image_4_tb_footer:hover {
    background-image: initial;
    background-color: #004678;
}

.et_pb_image_1_tb_footer {
    background-color: rgba(0, 0, 0, 0);
    margin: 20px 10px;
    width: 40px;
    transition: background-color 300ms ease 0ms, background-image 300ms ease 0ms;
}

.et_pb_image_1_tb_footer .et_pb_image_wrap {
    border-radius: 1px 1px 1px 1px;
    overflow: hidden;
    display: inline-block;
    position: relative;
    max-width: 100%;
}

.et_pb_image_2_tb_footer {
    background-color: rgba(0, 0, 0, 0);
    margin: 20px 10px;
    width: 40px;
    transition: background-color 300ms ease 0ms, background-image 300ms ease 0ms;
}

.et_pb_image_3_tb_footer {
    background-color: rgba(0, 0, 0, 0);
    margin: 20px 10px;
    width: 40px;
    transition: background-color 300ms ease 0ms, background-image 300ms ease 0ms;
}


.et_pb_image_4_tb_footer {
    background-color: rgba(0, 0, 0, 0);
    margin: 20px 10px;
    width: 40px;
    transition: background-color 300ms ease 0ms, background-image 300ms ease 0ms;
}


.et_pb_row:after, .et_pb_row_inner:after, .et_pb_slides:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
    width: 0;
}


.et_pb_fullwidth_menu--without-logo .et_pb_menu__menu > nav > ul > li, .et_pb_menu--without-logo .et_pb_menu__menu > nav > ul > li {
    margin-top: 8px;
}

.et_pb_fullwidth_menu .et_pb_menu__menu > nav > ul > li, .et_pb_menu .et_pb_menu__menu > nav > ul > li {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.et_pb_fullwidth_menu .et-menu > li, .et_pb_menu .et-menu > li {
    padding-left: 11px;
    padding-right: 11px;
}

.et-menu li {
    display: inline-block;
    font-size: 14px;
    padding-right: 22px;
}

.nav li {
    position: relative;
    line-height: 1em;
}


.et-menu a {
    text-decoration: none;
    display: block;
    position: relative;
}

.et_pb_menu_0_tb_footer.et_pb_menu ul li a {
    color: #bfe5ff !important
}

.et-menu a, .et-menu a:hover {
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.et-menu a:hover {
    color: #eabd4b !important
}


.et_pb_row_0_tb_footer.et_pb_row {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.et_pb_menu_0_tb_footer {
    padding-right: 0;
}

.et_pb_text_inner {
    position: relative;
}

.et_pb_fullwidth_menu--style-left_aligned .et_pb_menu__wrap, .et_pb_menu--style-left_aligned .et_pb_menu__wrap {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.et_builder_inner_content {
    position: relative;
    z-index: 100;
}

.et_pb_menu__wrap .mobile_menu_bar {
    -webkit-transform: translateY(3%);
    transform: translateY(3%);
}

.mobile_menu_bar {
    position: relative;
    display: block;
    line-height: 0;
}


.et_mobile_menu {
    position: absolute;
    left: 0;
    padding: 5%;
    background: #fff;
    width: 100%;
    visibility: visible;
    opacity: 1;
    z-index: 9999;
    border-top: 3px solid #f2bc27;;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
}

.et_mobile_menu li a {
    border-bottom: 1px solid rgba(0, 0, 0, .03);
    color: #666;
    padding: 10px 5%;
    display: block;
}

.et_mobile_menu li a, .nav li li a {
    font-size: 14px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.et_mobile_menu li a:hover, .nav ul li a:hover, .et_mobile_menu li a.active {
    background-color: rgba(0, 0, 0, .03);
}


.et_pb_menu_0_tb_footer.et_pb_menu .et_mobile_menu, .et_pb_menu_0_tb_footer.et_pb_menu .et_mobile_menu ul {
    background-color: rgba(0, 0, 0, 0) !important;
}


.et_pb_menu_0_tb_footer .mobile_nav .mobile_menu_bar:before {
    color: #004678;
}

.header {
    width: 100%;
    z-index: 3;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
}

.header li a {
    display: block;
    padding: 20px 20px;
    text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
    background-color: #f4f4f4;
}


.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
}

.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 0 20px 25px 0;
    position: relative;
    user-select: none;
}

.header .menu-icon .navicon {
    background: #fff;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #fff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 5px;
}

.header .menu-icon .navicon:after {
    top: -5px;
}

.header .menu-btn {
    display: none;
}

.header .menu-btn:checked ~ .menu {
    margin-top: 40px;
    max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
    background: #fff;
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
    background: #fff;
}

.et_pb_tandc {
    float: left;
    width: 100%;
}

.et_mobile_nav_menu {
    float: right;
    display: none;
}

.mobile_menu_bar:before {
    position: absolute;
}


.et_pb_text_0 h2 {
    font-weight: 700;
    font-size: 48px;
    color: #002540 !important;
    line-height: 1.6em;
    text-align: center;
}

@media only screen and (max-width: 1400px) {
    .video_container {
        display: flex;
        flex-wrap: wrap;
    }

    .react-hls-player-kunden {
        width: 100%;
        height: auto;
    }

    .video_element_kunden {
        width: 600px !important;
        height: auto !important
    }

    .video-react {
        width: 100% !important;
    }
}

@media only screen and (min-width: 1350px) {
    .et_pb_row {
        padding: 27px 0;
    }
}

@media (max-width: 1300px) {
    .wann_arbeiten {
        flex-wrap: wrap;
    }

    .et_parallax_bg {
        background-repeat: repeat-y


    }

    .wann_arbeiten > * {
        width: 100%;
    }

    .et_pb_contact_field_checkbox_group_right {
        margin-left: 0;
        margin-top: 20px;
    }

    .et_pb_contact_field_checkbox_group_right span {
        text-align: left;
    }

}

@media only screen and (max-width: 1600px) {
    .et_pb_menu_0_tb_header {
        padding: 0 !important;
    }

    .et_pb_menu .et_pb_menu__menu {
        display: none;
    }

    .et_mobile_nav_menu {
        display: block;
    }

    .header-content-container.center {
        padding-top: 80px;
    }

}

@media only screen and (min-width: 981px) {


    .et_pb_gutters3 .et_pb_column_2_3, .et_pb_gutters3.et_pb_row .et_pb_column_2_3 {
        width: 64.833%;
    }

    .et_pb_image_0_tb_header {
        width: 226px;
    }

    .et_pb_gutters3 .et_pb_column_1_3, .et_pb_gutters3.et_pb_row .et_pb_column_1_3 {
        width: 29.6667%;
    }

    .et_pb_gutters3 .et_pb_column, .et_pb_gutters3.et_pb_row .et_pb_column {
        margin-right: 5.5%;
    }


    .et_pb_image_0_tb_footer {
        max-width: 100px;
        margin-top: -30px !important;
        text-align: center;
    }

    .text_under_smiley, .text_under_smiley a {
        color: #bfe5ff;
        font-size: 10pt;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
        display: block;
    }

    .et_pb_column_1_3, .et_pb_row .et_pb_column_1_3 {
        width: 29.6667%;
    }

    .et_pb_column, .et_pb_row .et_pb_column {
        margin-right: 5.5%;
    }


    .et_pb_column_4_4, .et_pb_row .et_pb_column_4_4 {
        width: 100%
    }


    .et_pb_row {
        padding: 2% 0;
    }

    .et_pb_column_1_3, .et_pb_row .et_pb_column_1_3 {
        width: 29.6667%;
    }

    .et_pb_column_3_3, .et_pb_row .et_pb_column_3_3 {
        justify-content: flex-end;
        display: flex;
    }


    .et_pb_row.et_pb_equal_columns, .et_pb_row_inner.et_pb_equal_columns, .et_pb_section.et_pb_equal_columns > .et_pb_row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        direction: ltr;
    }

    .et_pb_row.et_pb_equal_columns > .et_pb_column, .et_pb_section.et_pb_equal_columns > .et_pb_row > .et_pb_column {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }


}


@media (max-width: 980px) {


    .header-content-container.center {
        padding-top: 56px;
    }

    .et_pb_text_0 h2 {
        font-size: 38px;
    }

    .text_under_smiley, .text_under_smiley a {
        margin-top: 25px !important;
        font-size: 10pt;
    }

    .et_pb_row_onlyHome {
        padding: 10px 0;
    }

    .text_over_search {
        padding-top: unset;
    }

    .et_pb_column_2_5 {
        margin-bottom: unset;
    }

    .et_pb_section_0_tb_header.et_pb_section {
        padding-top: 15px;
        padding-bottom: 17px;
    }

    .nav-two-column.et_pb_column {
        width: 50% !important;
        margin-bottom: 0;
    }

    .et_pb_image_0_tb_header {
        margin-top: 4px !important;
        width: 226px;
    }

    .et_pb_menu_0_tb_header {
        padding: 0;
    }


    .et_pb_image_0_tb_header .et_pb_image_wrap img {
        max-height: 40px;
    }

    .et_mobile_menu {
        list-style-type: none !important;
    }

    .et_pb_fullwidth_menu .et_mobile_nav_menu, .et_pb_menu .et_mobile_nav_menu {
        float: none;
        margin: 0 6px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .et_pb_column {
        width: 100%;
        margin-bottom: 30px;
    }

    .et_pb_row_0_tb_footer {
        height: auto;
    }

    .et_pb_image_2_tb_footer, .et_pb_image_3_tb_footer, .et_pb_image_4_tb_footer {
        margin-right: 10px !important;
    }

    .et_pb_row_0_tb_footer, body #page-container .et-db #et-boc .et-l .et_pb_row_0_tb_footer.et_pb_row, body.et_pb_pagebuilder_layout.single #page-container #et-boc .et-l .et_pb_row_0_tb_footer.et_pb_row, body.et_pb_pagebuilder_layout.single.et_full_width_page #page-container #et-boc .et-l .et_pb_row_0_tb_footer.et_pb_row {
        width: 100%
    }


    .et_pb_section_0_tb_footer.et_pb_section {
        padding-right: 20px;
        padding-left: 20px;
        margin-right: 0;
        margin-left: 0;
    }

    .et_pb_section_0_tb_footer {
        width: 100%;
    }


    .spinner-border-home {
        margin-top: 0 !important;
        margin-left: 20px !important;
        color: #004678 !important;
        vertical-align: middle;
    }

    .spinner-border-delete {
        margin-top: 30px !important;
        margin-left: 0 !important;
        color: #004678 !important;
        vertical-align: middle;
    }

    .spinner-border {
        margin-top: 0
    }

    .et_pb_contact_field_checkbox {
        padding: 0 !important;
    }

    .datenschutz_container_home.et_pb_contact_field {
        margin-top: 30px !important;
    }

    .text_under_smiley, .text_under_smiley a {
        color: #bfe5ff;
        text-align: center;
        margin: 55px auto 20px;
        left: 0;
        right: 0;
        display: block;
    }

    .et_pb_button_zusatz {
        padding-top: 10px;
        padding-bottom: 30px;

    }

    .et_pb_button_1 {
        justify-content: center;
        display: flex;
    }

    .et_pb_contact_form p.et_pb_contact_field, .et_pb_contact_form div.et_pb_contact_field {
        padding: 0 !important;
    }

    .et_pb_contact_form_0.et_pb_contact_form_container {
        padding-right: 20px;
        padding-left: 20px;
    }

    .et_pb_column_0_tb_footer {
        width: 33%;
        display: flex;
        margin-bottom: 0
    }

    .et_mobile_nav_menu .et_pb_text a {
        color: #bfe5ff !important;
        transition: color 300ms ease 0ms;
    }

    .et_mobile_nav_menu .et_pb_text a, .et_mobile_nav_menu .et_pb_text a:hover {
        transition: all .4s ease-in-out;
    }

    .et_mobile_nav_menu .et_pb_text a:hover {
        opacity: .7;
    }

    .et_mobile_nav_menu .et_pb_text:first-child {
        margin-right: 20px;
    }


    .et_pb_tandc label:first-child {
        margin-right: 10px;
    }

    .edit_container_profile {
        display: inline-flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .edit_container_profile button:first-child {
        margin-right: 0;
    }


    .form-check-label {
        margin-left: 0;
        margin-top: -1%;
    }

    .button-desktop {
        display: none
    }

    .button-mobile {
        display: inherit;
    }

    .navbar-brand {
        margin-right: 0 !important;
    }


    .wann_arbeiten_kurzlang {
        padding-top: 20px;
        padding-bottom: 0;
    }

    .work_label {
        display: block;

        padding-top: 0 !important;
    }


    .et_pb_contact_field_checkbox_group_right {
        margin-top: 30px;
        margin-left: 0;
    }

    .zusatzinfos_kurzlang {
        flex-wrap: wrap;

    }


    .wann_arbeiten_kurzlang {
        margin-left: 0;
        flex-wrap: wrap;
        justify-content: left;
        float: none;
    }

    .et_pb_contact_field_checkbox_group_right span {
        text-align: left;
    }

    .info-notice-phone {
        padding-bottom: 10px;
    }

    .et_pb_contact_form_container .et_pb_contact_field_0.et_pb_contact_field {
        margin-top: 20px !important;
    }

    .et_contact_bottom_container_search {
        padding-top: 10px;
    }

    .text_under_search {
        padding-top: 10px;
    }

    .et_contact_bottom_container {
        text-align: center;
        float: none;
        display: block;
    }

    .form-check-input {
        position: inherit;
        margin-left: 0;
        margin-top: 0;
    }


    .text_over_search_21 {
        padding-top: 20px !important;
    }

    .et_pb_contact_form .et_pb_contact_field_half {
        width: 100%;
        float: none;
    }

    .et_pb_contact_form_search {
        justify-content: space-between;
        flex-flow: row wrap;
    }

    .et_pb_contact_field_search {
        width: 100% !important;
    }


    .placeholder-margin-50 {
        margin-bottom: 0;
    }

    .et_pb_section {
        padding: 50px 0;
    }


    .et_pb_section_6.et_pb_section {
        padding-top: 100px;
        padding-bottom: 230px;
    }


    .et_pb_menu__wrap {
        float: none;
    }

    .et_pb_menu_0_tb_footer.et_pb_menu ul li a {
        color: #194674 !important;
    }

    .et_pb_menu_0_tb_footer.et_pb_menu ul li a:hover {
        color: #eabd4b !important;
    }

    .et-l--footer {
        position: inherit;
    }

    .et_pb_fullwidth_menu .et_pb_menu__menu, .et_pb_menu .et_pb_menu__menu {
        display: none;
    }

    .et_mobile_nav_menu {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        justify-content: center;
    }

    .et_mobile_menu, .et_mobile_menu ul, .et_pb_menu .et_mobile_menu, .et_pb_menu .et_mobile_menu ul {
        list-style: none !important;
        text-align: left;
    }

    .et_mobile_menu, .et_pb_menu .et_mobile_menu {
        top: 100%;
        padding: 5%;
    }

    .et_pb_row {
        max-width: 1080px;
    }

    .et_pb_column .et_pb_row_inner, .et_pb_row {
        padding: 10px 0;
    }


    .et_pb_column_1_tb_footer, .et_pb_column_2_tb_footer {
        width: 33%;
        margin-bottom: 0;
    }


    .et_pb_image_0_tb_footer {
        margin-top: -20px !important;
        text-align: center;
    }

    .et_pb_image_0_tb_footer img {
        max-width: 80px;
    }

    .et_pb_row .et_pb_column .et_pb_module.et-last-child, .et_pb_row .et_pb_column .et_pb_module:last-child, .et_section_specialty .et_pb_row .et_pb_column .et_pb_module.et-last-child, .et_section_specialty .et_pb_row .et_pb_column .et_pb_module:last-child {
        margin-bottom: 0;
    }


    .et_pb_contact_mobile {
        margin-bottom: 0 !important;
    }


    .et_pb_menu_inner_container {
        position: relative;
    }

    .et_pb_fullwidth_menu--style-left_aligned .et_pb_menu_inner_container, .et_pb_fullwidth_menu--style-left_aligned .et_pb_row, .et_pb_menu--style-left_aligned .et_pb_menu_inner_container, .et_pb_menu--style-left_aligned .et_pb_row {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .et_pb_fullwidth_menu--style-left_aligned .et_pb_menu__wrap, .et_pb_menu--style-left_aligned .et_pb_menu__wrap {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }


}

@media only screen and (min-width: 768px) and (max-width: 980px) {
    .et_pb_row_0_tb_footer {
        display: flex !important;
    }
}


@media only screen and (max-width: 767px) {


    .et_pb_section_0_tb_header.et_pb_section {
        padding: 5px 15px;
    }


    .et_pb_image_0_tb_header {
        margin-top: 9px !important;
        width: 169px;
    }

    .et_pb_image_0_tb_header .et_pb_image_wrap img {
        max-height: 30px;
    }

    .et_pb_menu_0_tb_header {
        padding-top: 0;
        padding-bottom: 0;
    }


    .et_pb_row_0_tb_footer {
        height: auto;
        display: flex !important;
        flex-direction: column !important;
    }

    .et_pb_column_0_tb_footer, .et_pb_column_1_tb_footer, .et_pb_column_2_tb_footer {
        width: 100%;

    }

    .et_pb_column_0_tb_footer, .et_pb_column_1_tb_footer {
        margin-bottom: 30px;
    }

    .et_pb_column_0_tb_footer {

        order: 2;
        display: flex;
        justify-content: center !important;
    }

    .et_pb_column_1_tb_footer {
        order: 1;
    }

    .et_pb_column_2_tb_footer {
        order: 3;
        display: flex;
        justify-content: center !important;
        flex-direction: row !important;
        margin-bottom: 0;
    }

    .et_pb_image_1_tb_footer, .et_pb_image_2_tb_footer, .et_pb_image_3_tb_footer, .et_pb_image_4_tb_footer {
        margin: 0 5px 20px 5px !important;
    }

    .et_pb_text_0_tb_footer {
        display: inline-block;
        float: none;
    }


    .et_pb_row_0_tb_footer {
        height: auto;
        display: flex !important;
        flex-direction: column !important;
    }
}

@media (max-width: 720px) {
    .ant-checkbox-group > *, .ant-radio-group > * {
        margin-top: 30px;
        width: 100%;
    }

    .work_label {
        padding-bottom: 0 !important;
    }


}

@media only screen and (max-width: 600px) {
    .et_mobile_menu {
        width: 90vw;
        margin-left: -45vw;
    }
}

@media (max-width: 470px) {

    .border-flyer {
        margin-top: 20px !important;
    }
}

@media (max-width: 400px) {

    .et_pb_section .et_pb_jobsearch.et_pb_jobsearch_container.et_pb_module .et_pb_button, .ant-btn, .et_pb_section .et_pb_contact_form_0.et_pb_contact_form_container.et_pb_module .et_pb_button {
        height: 70px !important;
    }
}


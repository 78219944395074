/* open-sans-300 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/open-sans-v27-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('fonts/open-sans-v27-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/open-sans-v27-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/open-sans-v27-latin-300.woff') format('woff'), /* Modern Browsers */ url('fonts/open-sans-v27-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/open-sans-v27-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/open-sans-v27-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('fonts/open-sans-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/open-sans-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/open-sans-v27-latin-regular.woff') format('woff'), /* Modern Browsers */ url('fonts/open-sans-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/open-sans-v27-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/open-sans-v27-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('fonts/open-sans-v27-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/open-sans-v27-latin-600.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/open-sans-v27-latin-600.woff') format('woff'), /* Modern Browsers */ url('fonts/open-sans-v27-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/open-sans-v27-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

body {
    font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
    line-height: 1.5em;
    font-size: 1rem;
    color: #000;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}
